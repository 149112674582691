<template>
  <b-container>
    <validation-observer
      ref="observer"
      #default="{ invalid }"
    >

      <b-card>
        <b-form>
          <b-form-group
            label="Tipo"
            label-for="type"
          >
            <b-form-select
              v-model="type"
              :options="[
                {value: null, text: 'Selecione um tipo'},
                { value: 'budget', text: 'Orçamento'},
                { value: 'adamas', text: 'Adamas Registro de Pagamento' }
              ]"
            />
          </b-form-group>
          <b-form-group
            label="Arquivo"
            label-for="file"
          >
            <validation-provider
              #default="{ errors }"
              name="Upload"
              rules="required|xlsx"
            >
              <b-form-file
                v-model="file"
                :state="errors.length > 0 ? false : null"
                placeholder="Arraste ou selecione um arquivo"
                drop-placeholder="Arraste o arquivo aqui."
                browse-text="Procurar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            variant="primary"
            :disabled="invalid"
            @click="sendFile"
          >
            Enviar
          </b-button>
        </b-form>
      </b-card>
    </validation-observer>
  </b-container>
</template>

<script>
import {
    BFormFile, BFormGroup, BForm, BContainer, BCard, BButton, BFormSelect,
} from 'bootstrap-vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

import { uploadAdamas, uploadBudget } from '@/service/file/others';

import '@validations';

export default {
    components: {
        BButton,
        BCard,
        BContainer,
        BFormFile,
        BFormGroup,
        BForm,
        ValidationObserver,
        ValidationProvider,
        BFormSelect,
    },

    data() {
        return {
            file: null,
            type: null,
        };
    },

    methods: {
        async sendFile() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente enviar o arquivo?');
            if (!isConfirmed) return;

            this.callLoading(true);
            const status = this.type === 'adamas' ? await uploadAdamas(this.file) : await uploadBudget(this.file);
            this.callLoading(false);
            if (status === 200) this.modalSuccess('Arquivo importado com sucesso');
            else this.modalError('Erro ao enviar arquivo');
        },
    },
};
</script>
