var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',[_c('b-form',[_c('b-form-group',{attrs:{"label":"Tipo","label-for":"type"}},[_c('b-form-select',{attrs:{"options":[
              {value: null, text: 'Selecione um tipo'},
              { value: 'budget', text: 'Orçamento'},
              { value: 'adamas', text: 'Adamas Registro de Pagamento' }
            ]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('b-form-group',{attrs:{"label":"Arquivo","label-for":"file"}},[_c('validation-provider',{attrs:{"name":"Upload","rules":"required|xlsx"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Arraste ou selecione um arquivo","drop-placeholder":"Arraste o arquivo aqui.","browse-text":"Procurar"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.sendFile}},[_vm._v(" Enviar ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }